exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/About.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/Contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-game-rules-js": () => import("./../../../src/pages/en/GameRules.js" /* webpackChunkName: "component---src-pages-en-game-rules-js" */),
  "component---src-pages-en-imprint-js": () => import("./../../../src/pages/en/Imprint.js" /* webpackChunkName: "component---src-pages-en-imprint-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-safari-s-1-animals-js": () => import("./../../../src/pages/en/Safari/S1/Animals.js" /* webpackChunkName: "component---src-pages-en-safari-s-1-animals-js" */),
  "component---src-pages-en-safari-s-1-biomes-js": () => import("./../../../src/pages/en/Safari/S1/Biomes.js" /* webpackChunkName: "component---src-pages-en-safari-s-1-biomes-js" */),
  "component---src-pages-en-safari-s-1-items-js": () => import("./../../../src/pages/en/Safari/S1/Items.js" /* webpackChunkName: "component---src-pages-en-safari-s-1-items-js" */),
  "component---src-pages-en-stats-js": () => import("./../../../src/pages/en/Stats.js" /* webpackChunkName: "component---src-pages-en-stats-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-animal-card-js": () => import("./../../../src/templates/AnimalCard.js" /* webpackChunkName: "component---src-templates-animal-card-js" */),
  "component---src-templates-biome-card-js": () => import("./../../../src/templates/BiomeCard.js" /* webpackChunkName: "component---src-templates-biome-card-js" */),
  "component---src-templates-item-card-js": () => import("./../../../src/templates/ItemCard.js" /* webpackChunkName: "component---src-templates-item-card-js" */)
}

